import { Routes } from '@angular/router';
import { LoginComponent, authGuard } from '../../../domain/src/public-api';

export const routes: Routes = [
    {
        path: 'resolve',
        loadChildren: () => import('./modules/resolve/resolve.routes'),
        canActivate: [authGuard]
    },
    { path: 'login', component: LoginComponent },
    {
        path: '',
        loadChildren: () => import('./modules/signup/signup.routes'),
        canActivate: [authGuard]
    }
];
