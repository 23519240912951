import { Injectable } from '@angular/core';
import { SnippetOptions, FullStory, init as initFullstory } from '@fullstory/browser';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullstoryService {

  public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(authService: AuthService) {
    combineLatest([
      this.isInitialized$,
      authService.getUser()
    ]).pipe(
      filter(([isInitialized, user]) => isInitialized && !!user)
    ).subscribe(([_, user]) => {
      FullStory('setIdentity', {
        uid: user.sub,
        properties: {
          displayName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          publisherIds: user.publisherIds,
          roles: user.roles
        }
      });
    });
  }

  public initialize(config: SnippetOptions): Promise<void> {
    initFullstory(config, () => this.isInitialized$.next(true));
    return Promise.resolve();
  }
}
