import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { switchMap, tap } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const roles: string[] = route.data['roles'];

  return authService.getIsAuthenticated().pipe(
    tap((isAuthenticated) => {
      if (!isAuthenticated) {
        authService.login();
        router.navigate(['login']);
      }
    }),
    switchMap(() => authService.isInAtLeastOneRole(roles)),
    tap(isInRole => {
      if (!isInRole) {
        router.navigate(['forbidden']);
      }
    })
  );
};
