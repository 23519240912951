import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { APPINSIGHTS_CONNECTIONSTRING, provideFullstory } from '../../../domain/src/public-api';
import { BASE_PATH } from 'stactize-signup-bff-sdk';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(),
    { provide: APPINSIGHTS_CONNECTIONSTRING, useValue: environment.applicationInsights.connectionString },
    { provide: BASE_PATH, useValue: '.' }, //Base Path for SDK is root
    provideFullstory({
      orgId: environment.fullstory.orgId,
      devMode: !environment.fullstory.isEnabled
    })
  ]
};
